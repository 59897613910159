import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Resources from './pages/Resources';
import Calculators from './pages/Calculators';

import Navbar from './components/Navbar';

function App() {
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const [isMenuOpen, setIsMenuOpen] = useState(false);


  return (
    <Router>
      {/* Header */}
      <Navbar toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />

      <div className={`overflow-hidden`} >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/resources" element={<Resources />} />
          <Route path="/resources/calculators" element={<Calculators />} />
        </Routes>
        {/* <Footer isDarkMode={isDarkMode} /> */}
      </div>
    </Router>
  );
}

export default App;