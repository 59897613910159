// Modal.js
import React from 'react';

const Modal = ({ isOpen, onClose, product }) => {
  if (!isOpen || !product) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg p-8 w-full max-w-lg relative">
        <button className="absolute top-0 right-2 text-gray-600 hover:text-gray-900 text-2xl" onClick={onClose}>
          &times;
        </button>
        <img src={product.image} alt={product.title} className="w-full h-48 object-cover mb-4 rounded" />
        <h3 className="text-2xl font-bold mb-2">{product.title}</h3>
        <p className="text-gray-600 mb-4">{product.description}</p>
        <p className="text-gray-600">{product.details}</p>
      </div>
    </div>
  );
};

export default Modal;
