import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from "../images/JalaramLogo.svg"

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navigate = useNavigate();

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleNavigation = (path) => {
        navigate(path);
        setIsMenuOpen(false); // Close the menu on navigation
    };

    return (
        <div>
            {/* Header */}
            <header className="fixed top-0 left-0 right-0 bg-white shadow-md z-20">
                <nav className="container mx-auto flex items-center justify-between py-2 px-2 sm:px-0">
                <div className="flex items-center">
                        {/* Logo */}
                        <img src={Logo} alt="Logo" className="h-16 w-16 mr-2 cursor-pointer" onClick={() => handleNavigation('/')} />
                        {/* Navigation Text */}
                        <div 
                            className="font-bold text-xl cursor-pointer"
                            onClick={() => handleNavigation('/')}
                        >
                            Jalaram Wire Mesh Co
                        </div>
                    </div>
                    <div className="hidden md:flex items-center space-x-4">
                        {/* Desktop Menu */}
                        <div 
                            className="hover:text-gray-500 cursor-pointer"
                            onClick={() => handleNavigation('/')}
                        >
                            Home
                        </div>
                        <div 
                            className="hover:text-gray-500 cursor-pointer"
                            onClick={() => handleNavigation('/resources')}
                        >
                            Resources
                        </div>
                        <div 
                            className="hover:text-gray-500 cursor-pointer"
                            onClick={() => handleNavigation('/products')}
                        >
                            Products
                        </div>
                        <div 
                            className="hover:text-gray-500 cursor-pointer"
                            onClick={() => handleNavigation('/contact')}
                        >
                            Contact
                        </div>
                    </div>
                    <div className="flex items-center md:hidden">
                        <button
                            onClick={toggleMenu}
                            className="text-gray-600 hover:text-gray-800 focus:outline-none"
                        >
                            <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
                                <path
                                    fillRule="evenodd"
                                    d="M3 6a1 1 0 011-1h16a1 1 0 110 2H4a1 1 0 01-1-1zm0 6a1 1 0 011-1h16a1 1 0 110 2H4a1 1 0 01-1-1zm0 6a1 1 0 011-1h16a1 1 0 110 2H4a1 1 0 01-1-1z"
                                />
                            </svg>
                        </button>
                    </div>
                </nav>
                {/* Mobile Menu */}
                <div
                    className={`md:hidden fixed top-0 right-0 h-full w-full bg-white transition-transform duration-300 transform ${isMenuOpen ? 'translate-x-0' : 'translate-x-full'}`}
                >
                    <div className="flex flex-col justify-center items-center h-full w-full">
                        <button
                            onClick={toggleMenu}
                            className="absolute top-4 right-4 text-gray-600 hover:text-gray-800 focus:outline-none"
                        >
                            <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
                                <path
                                    fillRule="evenodd"
                                    d="M6.707 6.293a1 1 0 011.414 0L12 10.586l4.879-4.88a1 1 0 111.414 1.414L13.414 12l4.88 4.879a1 1 0 01-1.414 1.414L12 13.414l-4.879 4.88a1 1 0 01-1.414-1.414L10.586 12 5.707 7.121a1 1 0 010-1.414z"
                                />
                            </svg>
                        </button>
                        <ul className="flex flex-col items-center space-y-4">
                            <li>
                                <div
                                    className="hover:text-gray-500 cursor-pointer"
                                    onClick={() => handleNavigation('/')}
                                >
                                    Home
                                </div>
                            </li>
                            <li>
                                <div
                                    className="hover:text-gray-500 cursor-pointer"
                                    onClick={() => handleNavigation('/resources')}
                                >
                                    Resources
                                </div>
                            </li>
                            <li>
                                <div
                                    className="hover:text-gray-500 cursor-pointer"
                                    onClick={() => handleNavigation('/products')}
                                >
                                    Products
                                </div>
                            </li>
                            <li>
                                <div
                                    className="hover:text-gray-500 cursor-pointer"
                                    onClick={() => handleNavigation('/contact')}
                                >
                                    Contact
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </header>
        </div>
    );
};

export default Navbar;
