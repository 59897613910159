import React, { useState } from 'react';

const Modal = ({ isOpen, toggleModal, image, flipImage }) => {
    const [isFlipped, setIsFlipped] = useState(false);
    const materialDensities = {
        ss: {
            '201': 7930,
            '202': 7930,
            '301': 7930,
            '302': 7930,
            '303': 7930,
            '304': 7930,
            '304L': 7930,
            '304LN': 7930,
            '305': 7930,
            '321': 7930,
            '309S': 7980,
            '310S': 7980,
            '316': 7980,
            '316L': 7980,
            '316Ti': 7980,
            '316LN': 7980,
            '317': 7980,
            '317L': 7980,
            '347': 7980,
            '904L': 7980,
            '2205': 7800,
            'S31803': 7800,
            'S32750': 7850,
            '403': 7750,
            '410': 7750,
            '410S': 7750,
            '416': 7750,
            '431': 7750,
            '440A': 7740,
            '440C': 7620,
            '420': 7730,
            '439': 7700,
            '430': 7700,
            '430F': 7700,
            '434': 7740,
            '444': 7750,
            '405': 7720
        },

        aluminum: 2705,
        brass: 8587,
        'cast iron': 7300,
        copper: 8944,
        gold: 19320,
        iron: 7860,
        lead: 11343,
        mercury: 13570,
        'mild steel': 7850,
        platinum: 21425,
        silver: 10497,
        tin: 7260,
        titanium: 4520,
        tungsten: 19450,
        zinc: 7068,
    };

    const [formData, setFormData] = useState({
        weave: '',
        meshCount: '',
        wireDiameter: '',
        material: '',
        grade: '',
        warpMeshCount: '',
        weftMeshCount: '',
        warpWireDiameter: '',
        weftWireDiameter: '',
        density: 7900
    });
    const [result, setResult] = useState({
        warpWeight: '',
        weftWeight: '',
        totalWeight: ''
    });
    const [unit, setUnit] = useState('mm'); // State to track the unit (mm or in)

    // Function to convert millimeters to meters
    const mmToMeters = (value) => {
        return value / 1000; // 1 meter = 1000 mm
    };

    // Function to convert inches to meters
    const inchToMeters = (value) => {
        return value * 0.0254; // 1 inch = 0.0254 meters
    };

    // Function to handle unit toggle
    const toggleUnit = () => {
        const newUnit = unit === 'mm' ? 'in' : 'mm';
        setUnit(newUnit);

        const wireDiameter = parseFloat(formData.wireDiameter);
        let convertedWireDiameter;

        if (unit === 'mm') {
            // Convert from mm to inches
            convertedWireDiameter = wireDiameter / 25.4;
        } else {
            // Convert from inches to mm
            convertedWireDiameter = wireDiameter * 25.4;
        }

        // Update the formData with the converted value
        setFormData({
            ...formData,
            wireDiameter: convertedWireDiameter.toFixed(3), // Adjusted to 10 decimal places
            warpWireDiameter: convertedWireDiameter.toFixed(3),
            weftWireDiameter: convertedWireDiameter.toFixed(3)
        });
    };

    // Reset form data and result
    const resetCalculation = () => {
        setFormData({
            weave: '',
            meshCount: '',
            wireDiameter: '',
            material: '',
            grade: '',
            warpMeshCount: '',
            weftMeshCount: '',
            warpWireDiameter: '',
            weftWireDiameter: '',
            density: 7900
        });
        setResult({
            warpWeight: '',
            weftWeight: '',
            totalWeight: ''
        });
        setUnit('mm'); // Reset unit to default (mm)
    };

    // Handle form input changes
    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        let newFormData = { ...formData, [name]: value };

        if (name === 'material' || name === 'grade') {
            if (name === 'material' && value !== 'ss') {
                newFormData.grade = ''; // Reset grade if material is not stainless steel
                newFormData.density = materialDensities[value] || 7900;
            } else if (name === 'material' && value === 'ss') {
                newFormData.density = 7900; // Default SS density if grade not selected
            } else if (name === 'grade' && formData.material === 'ss') {
                newFormData.density = materialDensities.ss[value] || 7900;
            }
        }

        setFormData(newFormData);
    };

    // Calculate result for Square weave
    const calculateSquareResult = (e) => {
        e.preventDefault();
        const { meshCount, wireDiameter, density } = formData;
        const diameterInMeters = unit === 'mm' ? mmToMeters(parseFloat(wireDiameter)) : inchToMeters(parseFloat(wireDiameter));
        const lengthInMeters = 2 * parseFloat(meshCount) * 39.37;
        const weight = 0.79 * Math.pow(diameterInMeters, 2) * lengthInMeters * density;
        setResult({
            ...result,
            totalWeight: weight.toFixed(3)
        });
    };

    // Calculate result for Dutch weave
    const calculateDutchResult = (e) => {
        e.preventDefault();
        const { warpMeshCount, weftMeshCount, warpWireDiameter, weftWireDiameter, density } = formData;
        const warpDiameterInMeters = unit === 'mm' ? mmToMeters(parseFloat(warpWireDiameter)) : inchToMeters(parseFloat(warpWireDiameter));
        const weftDiameterInMeters = unit === 'mm' ? mmToMeters(parseFloat(weftWireDiameter)) : inchToMeters(parseFloat(weftWireDiameter));
        const lengthInMetersWarp = 2 * parseFloat(warpMeshCount) * 39.37; // Assuming same length for both warp and weft
        const lengthInMetersWeft = 2 * parseFloat(weftMeshCount) * 39.37; // Assuming same length for both warp and weft
        const warpWeight = (0.79 * Math.pow(warpDiameterInMeters, 2) * lengthInMetersWarp * density) / 2;
        const weftWeight = (0.79 * Math.pow(weftDiameterInMeters, 2) * lengthInMetersWeft * density) / 2;
        setResult({
            warpWeight: warpWeight.toFixed(3),
            weftWeight: weftWeight.toFixed(3),
            totalWeight: (warpWeight + weftWeight).toFixed(3)
        });
    };

    // Handle form submission based on weave type
    const handleSubmit = (e) => {
        e.preventDefault();
        if (formData.weave === 'square') {
            calculateSquareResult(e);
        } else if (formData.weave === 'dutch') {
            calculateDutchResult(e);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
            <div className="bg-white rounded-lg overflow-hidden max-w-full w-full p-4 m-2 md:max-w-5xl md:w-full">
                <div className="flex justify-between items-center px-6 py-4 border-b">
                    <h2 className="text-xl font-semibold">Weight Calculator</h2>
                    <button className="text-gray-600 hover:text-gray-800 focus:outline-none" onClick={() => {
                        toggleModal();
                        resetCalculation();
                    }}>
                        <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
                            <path
                                fillRule="evenodd"
                                d="M6.707 6.293a1 1 0 011.414 0L12 10.586l4.879-4.88a1 1 0 111.414 1.414L13.414 12l4.879 4.879a1 1 0 01-1.414 1.414L12 13.414l-4.879 4.879a1 1 0 11-1.414-1.414L10.586 12 6.707 7.121a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </button>
                </div>
                <div className="flex flex-col md:flex-row md:p-6">
                    <div className="md:w-1/2 md:pr-6">
                        <div className="mt-2 bg-gray-100 p-4 rounded-lg">
                            <div className={`relative ${isFlipped ? 'flipped' : ''}`}>
                                <img
                                    src={isFlipped ? flipImage : image}
                                    alt="Example"
                                    className="w-full h-auto"
                                />
                                <button
                                    className="absolute top-2 right-2 text-white bg-gray-800 bg-opacity-50 rounded-full p-2 hover:bg-opacity-75 focus:outline-none"
                                    onClick={() => {
                                        setIsFlipped(!isFlipped);
                                    }}
                                >
                                    Flip
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="md:w-1/2 md:pl-6 mt-4 md:mt-0">
                        <form className="space-y-4" onSubmit={handleSubmit}>
                            <div className="flex flex-col space-y-2">
                                <label className="text-m font-medium text-gray-700">Weave Type</label>
                                <select
                                    className="rounded-lg border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                                    name="weave"
                                    value={formData.weave}
                                    onChange={handleFieldChange}
                                >
                                    <option value="">Select Weave Type</option>
                                    <option value="square">Square</option>
                                    <option value="dutch">Dutch</option>
                                </select>
                            </div>
                            {formData.weave === 'square' && (
                                <>
                                    <div className="flex flex-col space-y-2">
                                        <label className="text-m font-medium text-gray-700">Mesh Count</label>
                                        <input
                                            type="number"
                                            name="meshCount"
                                            className="rounded-lg border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                                            value={formData.meshCount}
                                            onChange={handleFieldChange}
                                        />
                                    </div>
                                    <div className="flex flex-col space-y-2">
                                        <label className="text-m font-medium text-gray-700">Wire Diameter ({unit})</label>
                                        <input
                                            type="number"
                                            name="wireDiameter"
                                            step="0.0000000001" // Adjusted step to a smaller value
                                            className="rounded-lg border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                                            value={formData.wireDiameter}
                                            onChange={handleFieldChange}
                                        />
                                    </div>
                                </>
                            )}
                            {formData.weave === 'dutch' && (
                                <>
                                    <div className="flex flex-col space-y-2">
                                        <label className="text-m font-medium text-gray-700">Warp Mesh Count</label>
                                        <input
                                            type="number"
                                            name="warpMeshCount"
                                            className="rounded-lg border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                                            value={formData.warpMeshCount}
                                            onChange={handleFieldChange}
                                        />
                                    </div>
                                    <div className="flex flex-col space-y-2">
                                        <label className="text-m font-medium text-gray-700">Weft Mesh Count</label>
                                        <input
                                            type="number"
                                            name="weftMeshCount"
                                            className="rounded-lg border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                                            value={formData.weftMeshCount}
                                            onChange={handleFieldChange}
                                        />
                                    </div>
                                    <div className="flex flex-col space-y-2">
                                        <label className="text-m font-medium text-gray-700">Warp Wire Diameter ({unit})</label>
                                        <input
                                            type="number"
                                            name="warpWireDiameter"
                                            step="0.0000000001" // Adjusted step to a smaller value
                                            className="rounded-lg border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                                            value={formData.warpWireDiameter}
                                            onChange={handleFieldChange}
                                        />
                                    </div>
                                    <div className="flex flex-col space-y-2">
                                        <label className="text-m font-medium text-gray-700">Weft Wire Diameter ({unit})</label>
                                        <input
                                            type="number"
                                            name="weftWireDiameter"
                                            step="0.0000000001" // Adjusted step to a smaller value
                                            className="rounded-lg border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                                            value={formData.weftWireDiameter}
                                            onChange={handleFieldChange}
                                        />
                                    </div>
                                </>
                            )}
                            <div className="flex flex-col space-y-2">
                                <label className="text-m font-medium text-gray-700">Material</label>
                                <select
                                    className="rounded-lg border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                                    name="material"
                                    value={formData.material}
                                    onChange={handleFieldChange}
                                >
                                    <option value="">Select Material</option>
                                    <option value="ss">Stainless Steel</option>
                                    <option value="aluminum">Aluminum</option>
                                    <option value="brass">Brass</option>
                                    <option value="cast iron">Cast Iron</option>
                                    <option value="copper">Copper</option>
                                    <option value="gold">Gold</option>
                                    <option value="iron">Iron</option>
                                    <option value="lead">Lead</option>
                                    <option value="mercury">Mercury</option>
                                    <option value="mild steel">Mild Steel</option>
                                    <option value="platinum">Platinum</option>
                                    <option value="silver">Silver</option>
                                    <option value="tin">Tin</option>
                                    <option value="titanium">Titanium</option>
                                    <option value="tungsten">Tungsten</option>
                                    <option value="zinc">Zinc</option>
                                </select>
                            </div>
                            {formData.material === 'ss' && (
                                <div className="flex flex-col space-y-2">
                                    <label className="text-sm font-medium text-gray-700">Grade</label>
                                    <select
                                        className="rounded-lg border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                                        name="grade"
                                        value={formData.grade}
                                        onChange={handleFieldChange}
                                    >
                                        <option value="">Select Grade</option>
                                        {Object.keys(materialDensities.ss).map((grade) => (
                                            <option key={grade} value={grade}>{grade}</option>
                                        ))}
                                    </select>
                                </div>
                            )}
                            <button
                                type="button"
                                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                onClick={toggleUnit}
                            >
                                Toggle to {unit === 'mm' ? 'inches' : 'millimeters'}
                            </button>
                            <div className="flex justify-between">
                                <button
                                    type="submit"
                                    className="w-full inline-flex items-center justify-center rounded-lg border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                                >
                                    Calculate
                                </button>
                            </div>
                        </form>
                        <div className='mt-5'>
                            <div className="bg-gray-100 p-4 rounded-lg">
                                {result.totalWeight && (
                                    <div>
                                        <h3 className="text-lg font-semibold mb-4">Result</h3>
                                        {formData.weave === 'dutch' && (
                                            <>
                                                <p className="mb-2">Warp Weight: {result.warpWeight} kgs</p>
                                                <p className="mb-2">Weft Weight: {result.weftWeight} kgs</p>
                                            </>
                                        )}
                                        <p className="mb-2">Total Weight: {result.totalWeight} kgs</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
