import React, { useState, useEffect } from 'react';

const AppertureNOpenArea = ({
    isOpen,
    toggleModal,
    formulaData,
    handleInputChange,
    handleSubmit,
    formData,
    result,
    setFormData
}) => {
    const [isFlipped, setIsFlipped] = useState(false);
    const [unit, setUnit] = useState('mm');

    useEffect(() => {
        if (!isOpen) {
            setIsFlipped(false);
            setUnit('mm');
        }
    }, [isOpen]);

    const toggleUnit = () => {
        const newUnit = unit === 'mm' ? 'in' : 'mm';
        setUnit(newUnit);

        const wireDiameter = parseFloat(formData.wireDiameter);
        let convertedWireDiameter;

        if (unit === 'mm') {
            convertedWireDiameter = wireDiameter / 25.4;
        } else {
            convertedWireDiameter = wireDiameter * 25.4;
        }

        setFormData(prevData => ({
            ...prevData,
            wireDiameter: convertedWireDiameter.toFixed(10),
            unit: newUnit
        }));
    };

    const resetCalculation = () => {
        setFormData({
            Mesh: '',
            wireDiameter: '',
            unit: 'mm'
        });
        setUnit('mm');
    };

    if (!isOpen || !formulaData) return null;

    const { title, image, flipImage, fields, text: formulaText } = formulaData;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
            <div className="bg-white rounded-lg overflow-hidden max-w-full w-full md:max-w-5xl p-4 md:p-6">
                <div className="flex justify-between items-center px-4 py-2 border-b">
                    <h2 className="text-xl font-semibold">{title}</h2>
                    <button
                        className="text-gray-600 hover:text-gray-800 focus:outline-none"
                        onClick={() => {
                            toggleModal();
                            resetCalculation();
                        }}
                    >
                        <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
                            <path
                                fillRule="evenodd"
                                d="M6.707 6.293a1 1 0 011.414 0L12 10.586l4.879-4.88a1 1 0 111.414 1.414L13.414 12l4.88 4.879a1 1 0 01-1.414 1.414L12 13.414l-4.879 4.88a1 1 0 01-1.414-1.414L10.586 12 5.707 7.121a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </button>
                </div>
                <div className="flex flex-col md:flex-row md:p-6">

                    <div className="md:w-1/2 p-4 md:pr-6">
                    <div className="mt-2 bg-gray-100 p-4 rounded-lg">

                        <div className={`relative ${isFlipped ? 'flipped' : ''}`}>
                            <img
                                src={isFlipped ? flipImage : image}
                                alt="Calculator Image"
                                className="w-full h-auto cursor-pointer object-contain"
                                onClick={() => setIsFlipped(!isFlipped)}
                            />
                            <button
                                className="absolute top-2 right-2 text-white bg-gray-800 bg-opacity-50 rounded-full p-2 hover:bg-opacity-75 focus:outline-none"
                                onClick={() => setIsFlipped(!isFlipped)}
                            >
                                Flip
                            </button>
                        </div>
                    </div>
                    </div>
                    <div className="md:w-1/2 p-4 md:pl-6">
                        <p className="text-gray-600 mb-4">{formulaText}</p>
                        <form onSubmit={handleSubmit}>
                            {fields.map(field => (
                                <div key={field.name} className="mb-4">
                                    <label className="block text-sm font-medium text-gray-700">{field.label}</label>
                                    <div className="mt-2 flex items-center">
                                        <input
                                            type="number"
                                            id={field.name}
                                            name={field.name}
                                            value={formData[field.name]}
                                            onChange={handleInputChange}
                                            placeholder={`Enter ${field.label.toLowerCase()}`}
                                            className="block w-3/4 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                                            required
                                            step="any"
                                        />
                                        {field.name === 'wireDiameter' && (
                                            <span className="ml-2 text-sm text-gray-500">{unit}</span>
                                        )}
                                    </div>
                                </div>
                            ))}
                            <div className="flex items-center justify-between mb-4">
                                <button
                                    type="button"
                                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    onClick={toggleUnit}
                                >
                                    Toggle to {unit === 'mm' ? 'inches' : 'millimeters'}
                                </button>
                            </div>
                            <div className="mt-6 flex justify-end">
                                <button
                                    type="submit"
                                    className="w-full inline-flex items-center justify-center rounded-lg border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                                >
                                    Calculate
                                </button>
                            </div>
                        </form>
                        <div className="bg-gray-100 p-4 rounded-lg mt-5">

                        <div className="px-4 py-2 md:px-6 md:py-4">
                            {result.mainResult && (
                                <div className="mb-4">
                                    <h3 className="text-lg font-semibold">Result</h3>
                                    <p>Mesh opening/Apperture (w): <span className="font-bold">{result.mainResult} {unit}</span></p>
                                    <p>Open Area (Aₒ): <span className="font-bold">{result.perResult}</span> %</p>
                                </div>
                            )}
                        </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default AppertureNOpenArea;
