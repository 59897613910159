import React from 'react';
import { useNavigate } from 'react-router-dom';
import calculator from "../images/calculator-svgrepo-com.svg"
import video from "../images/videos-svgrepo-com.svg"
import conversion from "../images/convert-3d-cube-svgrepo-com.svg"

const Resources = () => {
    const navigate = useNavigate();

    const resources = [
        {
            title: 'Videos',
            description: 'Watch our latest videos.',
            link: '/resources/videos',
            icon: video
        },
        {
            title: 'Calculators',
            description: 'Use our calculators for various needs.',
            link: '/resources/calculators',
            icon: calculator,
                },
        {
            title: 'Conversion',
            description: 'Access Conversions and Tables.',
            link: '/resources/conversion',
            icon: conversion
        },
        {
            title: 'Downloads',
            description: 'Download useful resources.',
            link: '/resources/downloads',
            icon: (
                <svg className="w-12 h-12 text-red-500" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M12 16a1 1 0 001-1V5a1 1 0 10-2 0v10a1 1 0 001 1zm-1 4h2v2h-2zm7-3v1H6v-1a1 1 0 00-2 0v1a3 3 0 003 3h10a3 3 0 003-3v-1a1 1 0 00-2 0z" />
                </svg>
            ),
        },
    ];

    const handleNavigation = (path) => {
        navigate(path);
    };

    return (
        <div className='bg-gray-200 min-h-screen pt-10'>
            <div className="container mx-auto py-12 px-4 sm:px-6 lg:px-8">
                <h2 className="text-3xl font-extrabold text-gray-900 mb-6">Resources</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                    {resources.map((resource) => (
                        <div
                            key={resource.title}
                            className="p-6 border rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 bg-[#eff3fd]"
                            onClick={() => handleNavigation(resource.link)}
                            style={{ cursor: 'pointer' }}
                        >
                            <div className="flex items-center justify-center mb-4">
                                {/* Use the imported image as src attribute */}
                                <img src={resource.icon} alt={resource.title} className="w-12 h-12" />
                            </div>
                            <h3 className="text-xl font-semibold mb-2">{resource.title}</h3>
                            <p className="text-gray-600 mb-4">{resource.description}</p>
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleNavigation(resource.link);
                                }}
                                className="text-white bg-blue-500 hover:bg-blue-600 rounded px-4 py-2 transition-colors duration-300"
                            >
                                Explore
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};


export default Resources;
