import React, { useState, useEffect } from 'react';
import stainlessSteelWireMeshImage1 from "../images/stainless-steel-wire-mesh-twill-weave.jpg";
import stainlessSteelWireMeshImage2 from "../images/stainless-steel-wire-mesh-plain-weave.jpg";
import stainlessSteelWireMeshImage3 from "../images/stainless-steel-wire-mesh-plain-dutch-weave.jpg";
import stainlessSteelWireMeshImage4 from "../images/stainless-steel-wire-mesh-twill-dutch-weave.jpg";
import Enquire from '../components/Enquire';
import Modal from '../components/Modal'; // Import the Modal component
import img1 from "../images/all-images-1.jpg";
import img2 from "../images/all-images-2.jpg";
import img3 from "../images/all-images-3.jpg";

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const images = [
  img1,
  img2,
  img3,
];

const Home = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [selectedProduct, setSelectedProduct] = useState(null); // State for selected product

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const openModal = (product) => {
    setSelectedProduct(product);
    setIsModalOpen(true);
    document.body.classList.add('no-scroll'); // Disable scrolling
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
    document.body.classList.remove('no-scroll'); // Enable scrolling
  };



  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const [isFadingOut, setIsFadingOut] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsFadingOut(true);
      setTimeout(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        setIsFadingOut(false);
      }, 500); // Adjust this value to control the fade-out duration
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const currentImage = images[currentImageIndex];

  const products = [
    {
      image: stainlessSteelWireMeshImage2,
      title: 'Plain Wire Mesh',
      description: 'High-quality stainless steel wire mesh for various industrial applications.',
      link: '/portfolio/stainless_steel_wire_mesh/',
      details: 'This wire mesh is used in filtration, sieving, and for use in fencing. It offers great resistance to corrosion and high temperatures.',
    },
    {
      image: stainlessSteelWireMeshImage1,
      title: 'Twilled Wire Mesh',
      description: 'Twilled wire mesh is a versatile product that can be used in various applications.',
      link: '/portfolio/crimped_wire_mesh/',
      details: 'Twilled wire mesh provides higher strength and a finer mesh count, making it ideal for heavy-duty filtration and separation tasks.',
    },
    {
      image: stainlessSteelWireMeshImage3,
      title: 'Plain Dutch Wire Mesh',
      description: 'Ideal for liquid and slurry filtration, solid-liquid separation, and water treatment applications.',
      link: '/portfolio/welded_wire_mesh/',
      details: 'Plain Dutch weave wire mesh is known for its excellent mechanical properties and is commonly used in the chemical and aerospace industries.',
    },
    {
      image: stainlessSteelWireMeshImage4,
      title: 'Twill Dutch Wire Mesh',
      description: 'Durable wire mesh excels in critical filtration tasks, including high-pressure gas and liquid filtration.',
      link: '/portfolio/welded_wire_mesh/',
      details: 'Twill Dutch weave combines the best of both plain Dutch and twill weave, offering high filtration accuracy and strength for demanding applications.',
    },
  ];

  return (
    <div className="bg-gray-100 min-h-screen pt-8">
      {/* Hero Section */}
      <div className="relative">
        <div
          className={`absolute inset-0 bg-cover bg-center transition-opacity ${isFadingOut ? 'fade-out' : ''}`}
          style={{ backgroundImage: `url(${currentImage})` }}
        ></div>
        <section className="pt-52 relative text-center md:text-left z-10">
          <div className="absolute inset-0 bg-black opacity-65"></div>
          <div className="relative container mx-auto px-4 py-16">
            <h1 className="text-3xl md:text-4xl font-bold mb-4 text-white">
              Rich products you can choose
            </h1>
            <p className="text-lg md:text-xl mb-8 text-white">500+ Kinds of Wire Mesh</p>
            <button className="bg-white text-blue-600 py-2 px-4 rounded-md hover:bg-blue-100 inline-block mx-auto md:mx-0" onClick={openPopup}>Enquire Now</button>
            <Enquire isOpen={isPopupOpen} onClose={closePopup} />
          </div>
        </section>
      </div>

      {/* Service Section */}
      <section className="container mx-auto py-16 px-4 md:px-0">
        <h2 className="text-3xl font-bold mb-8 text-center">About Jalaram Wire Mesh Co</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
          <div className="bg-white rounded-lg shadow-md p-6 text-center">
            <span className="text-4xl text-blue-500">
              <i className="fas fa-clock"></i>
            </span>
            <h3 className="text-xl font-bold my-2">20+ years</h3>
            <p className="text-gray-600">Over 20 years of experience</p>
          </div>
          <div className="bg-white rounded-lg shadow-md p-6 text-center">
            <span className="text-4xl text-blue-500">
              <i className="fas fa-users"></i>
            </span>
            <h3 className="text-xl font-bold my-2">10+ employees</h3>
            <p className="text-gray-600">Small but fine team</p>
          </div>
          <div className="bg-white rounded-lg shadow-md p-6 text-center">
            <span className="text-4xl text-blue-500">
              <i className="fas fa-globe"></i>
            </span>
            <h3 className="text-xl font-bold my-2">PAN India</h3>
            <p className="text-gray-600">Presence Across Nation</p>
          </div>
          <div className="bg-white rounded-lg shadow-md p-6 text-center">
            <span className="text-4xl text-blue-500">
              <i className="fas fa-database"></i>
            </span>
            <h3 className="text-xl font-bold my-2">1000+ rolls</h3>
            <p className="text-gray-600">Over 1000 rolls in stock</p>
          </div>
        </div>
      </section>

      {/* Portfolio Section */}
      <section className="bg-gray-200 py-16 px-4 md:px-0">
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold mb-8 text-center">Wire Mesh Products</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
            {products.map((item, index) => (
              <div key={index} className="bg-white rounded-lg shadow-md overflow-hidden transition-all duration-500 flex flex-col">
                <img
                  src={item.image}
                  alt={item.title}
                  className="w-full h-48 object-cover"
                />
                <div className="p-4 flex flex-col flex-grow">
                  <h3 className="text-xl font-bold mb-2">{item.title}</h3>
                  <p className="text-gray-600 flex-grow">
                    {item.description}
                  </p>
                  <button
                    className="inline-block mt-4 bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600   self-center"
                    onClick={() => openModal(item)}
                  >
                    Learn More
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Modal */}
      <Modal isOpen={isModalOpen} onClose={closeModal} product={selectedProduct} />

      {/* Footer */}
      <footer className="bg-gray-800 text-white py-8">
        <div className="container mx-auto px-4 md:px-0">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div>
              <h3 className="text-xl font-bold mb-4">Recent Posts</h3>
              <ul>
                <li className="mb-2">
                  <a href="/blog/post-1/" className="hover:text-gray-400">
                    There are many variations of passages
                  </a>
                </li>
                <li className="mb-2">
                  <a href="/blog/post-2/" className="hover:text-gray-400">
                    Why Do We Use Wire Mesh?
                  </a>
                </li>
                <li className="mb-2">
                  <a href="/blog/post-3/" className="hover:text-gray-400">
                    Advantages of Stainless Steel Wire Mesh
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-4">Fantastic Wire Mesh Photos</h3>
              <div className="grid grid-cols-3 gap-2">
                <img
                  src="/images/gallery/IMG_3768.jpg"
                  alt="Wire Mesh"
                  className="w-full h-20 object-cover"
                />
                <img
                  src="/images/gallery/22.jpg"
                  alt="Wire Mesh"
                  className="w-full h-20 object-cover"
                />
                <img
                  src="/images/gallery/IMG_5694.jpg"
                  alt="Wire Mesh"
                  className="w-full h-20 object-cover"
                />
                <img
                  src="/images/gallery/IMG_5731.jpg"
                  alt="Wire Mesh"
                  className="w-full h-20 object-cover"
                />
                <img
                  src="/images/gallery/IMG_3766.jpg"
                  alt="Wire Mesh"
                  className="w-full h-20 object-cover"
                />
                <img
                  src="/images/gallery/IMG_5733.jpg"
                  alt="Wire Mesh"
                  className="w-full h-20 object-cover"
                />
              </div>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-4">Contact Us</h3>
              <p className="mb-2">
                <i className="fas fa-envelope mr-0"></i>
                <a href="mailto:thunderhit@qq.com" className="hover:text-gray-400">
                  jalaramwiremesh@rediffmail.com
                </a>
              </p>
              <p>
                <a href="tel:+919377899610" className="hover:text-gray-400">
                  +91 9377899610
                </a>
              </p>
              <p>
                <a href="tel:+919376225669" className="hover:text-gray-400">
                  +91 9376225669
                </a>
              </p>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-4">Location</h3>
              <p className="mb-2">
                <i className="fas fa-map-marker-alt mr-0"></i>Plot No. 205, POR INDUSTRIAL PARK, BEHIND HOTEL SAHYOG Besides POR GIDC Baroda, NH-8, Por, Gujarat 391243.
              </p>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2757.7798496512187!2d73.17946094771888!3d22.12183361208184!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395fc1acdb523ce1%3A0x35b2a1d28290adb!2sJALARAM%20WIREMESH%20CO!5e0!3m2!1sen!2sin!4v1718001015760!5m2!1sen!2sin"
                className="w-full h-64 md:h-96"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
          <div className="text-center mt-8">
            <p>&copy; Jalaram Wire Mesh Co, 2024</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Home;
