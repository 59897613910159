import React, { useState } from 'react';
import AppertureNOpenArea from '../components/Apperture&OpenArea'; 
import Weight_Calculator from '../components/Weight-Calculator'
import image1 from "../images/square-weave-open-area-calculation.png";
import image2 from "../images/square-weave-weight-calculation.jpg";
import formula1 from "../images/square-weave-calculation-formula.png";

const Calculator = () => {
    const [isModalOpen1, setIsModalOpen1] = useState(false);
    const [isModalOpen2, setIsModalOpen2] = useState(false);
    const [selectedFormula, setSelectedFormula] = useState(null);
    const [formData, setFormData] = useState({
        Mesh: '',
        wireDiameter: '',
        unit: 'mm'
    });
    const [result, setResult] = useState({ mainResult: '', perResult: '' });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };
    
    const handleUnitChange = (newUnit) => {
        setFormData(prevData => {
            const oldUnit = prevData.unit;
            const wireDiameter = parseFloat(prevData.wireDiameter);
            let newWireDiameter = wireDiameter;

            if (oldUnit === 'mm' && newUnit === 'in') {
                newWireDiameter = wireDiameter / 25.4;
            } else if (oldUnit === 'in' && newUnit === 'mm') {
                newWireDiameter = wireDiameter * 25.4;
            }

            return {
                ...prevData,
                wireDiameter: newWireDiameter.toString(),
                unit: newUnit
            };
        });
    };

    const calculateResult = (Mesh, wireDiameter, unit) => {
        if (selectedFormula) {
            const { formula, formula_Per } = selectedFormula;
            let mainResult = formula(parseFloat(Mesh), parseFloat(wireDiameter));
            
            if (unit === 'in') {
                mainResult /= 25.4; // Convert mm to inches
            }

            const perResult = formula_Per ? formula_Per(mainResult, parseFloat(wireDiameter)) : '';

            setResult({
                mainResult: mainResult.toFixed(3),
                perResult: perResult ? perResult.toFixed(3) : ''
            });
        }
    };
    
    const handleSubmit = (e) => {
        e.preventDefault();
        const { Mesh, wireDiameter, unit } = formData;
        calculateResult(Mesh, wireDiameter, unit);
    };
    
    const toggleAppertureNOpenArea = () => {
        setIsModalOpen1(!isModalOpen1);
        if (isModalOpen1) {
            resetCalculation();
        }
    };

    const toggleWeight_Calculator = () => {
        setIsModalOpen2(!isModalOpen2);
        if (isModalOpen2) {
            resetCalculation();
        }
    };

    const resetCalculation = () => {
        setFormData({ Mesh: '', wireDiameter: '', unit: 'mm' });
        setResult({ mainResult: '', perResult: '' });
    };

    const formulas = [
        {
            title: 'Apperture & Open Area % Calculator',
            formula: (Mesh, wireDiameter) => (25.4 / Mesh) - wireDiameter,
            formula_Per: (calculatedResult, wireDiameter) => (Math.pow(calculatedResult, 2) / Math.pow(calculatedResult + parseFloat(wireDiameter), 2)) * 100,
            image: image1,
            flipImage: formula1,
            fields: [
                { name: 'Mesh', label: 'Mesh (M)' },
                { name: 'wireDiameter', label: 'Wire Diameter (d)' }
            ],
            text: "Calculate Apperture and Open Area of Square Mesh Immediately"
        },
        {
            title: 'Weight Calculator',
            formula: (Mesh, wireDiameter) => (Math.pow(Mesh, 2) / Math.pow(Mesh + parseFloat(wireDiameter), 2)) * 100,
            image: image2,
            flipImage: image1,
            text: "Calculate the Weight of required Wire Mesh"
        },
    ];


    return (
        <div className="container mx-auto py-12 px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-extrabold text-gray-900 mb-6">Resources</h2>
            <h2 className="text-3xl font-extrabold text-gray-900 mb-6">Calculators</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                {formulas.map((formula, index) => (
                    <div
                        key={index}
                        className="p-6 border rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 cursor-pointer flex flex-col justify-between"
                        onClick={() => {
                            setSelectedFormula(formula);
                            index === 0 ? toggleAppertureNOpenArea() : toggleWeight_Calculator();
                        }}
                    >
                        <div className="flex flex-col items-center mb-4 flex-grow">
                            <img src={formula.image} alt="Calculator Image" className="w-40 h-40 object-contain" />
                            <h3 className="text-xl font-semibold mt-4 mb-2 text-center">{formula.title}</h3>
                            <p className="text-gray-600 text-center">{formula.text}</p>
                        </div>
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                setSelectedFormula(formula);
                                index === 0 ? toggleAppertureNOpenArea() : toggleWeight_Calculator();
                            }}
                            className="text-white bg-blue-500 hover:bg-blue-600 rounded px-4 py-2 transition-colors duration-300 mt-4 self-center"
                        >
                            Calculate
                        </button>
                    </div>
                ))}
            </div>

            <AppertureNOpenArea
                isOpen={isModalOpen1}
                toggleModal={toggleAppertureNOpenArea}
                formulaData={selectedFormula}
                handleInputChange={handleInputChange}
                handleSubmit={handleSubmit}
                formData={formData}
                result={result}
                setFormData={setFormData}
                setResult={setResult}
            />
            <Weight_Calculator    
                isOpen={isModalOpen2}
                toggleModal={toggleWeight_Calculator}
                formulaData={selectedFormula}
                handleInputChange={handleInputChange}
                handleSubmit={handleSubmit}
                image={formulas[1].image}
                flipImage={formulas[1].flipImage}

                formData={formData}
                result={result}
                setFormData={setFormData}
                setResult={setResult}
            />
        </div>
    );
};

export default Calculator;